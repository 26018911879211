@tailwind base;
@tailwind components;
@tailwind utilities;

#menus #children {
  display: none;
}

#menus #children.open {
  display: block;
}
